const state = {
  data: []
};

const mutations = {
  save(state, data) {
    state.data.push(data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
