import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.filter('number', function (value) {
  if (value || value == 0) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  else
    return ''
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
