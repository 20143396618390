const state = {
  data: {},
  kode_transaksi: "",
  checkout_at: null,
  expired_at: null,
};

const mutations = {
  save(state, data) {
    state.data = data;
  },
  add(state, data) {
    state.data.push(data);
  },
  setTransaksi(state, data) {
    state.kode_transaksi = data.kode_transaksi;
    state.checkout_at = data.checkout_at;
    state.expired_at = data.expired_at;
  },
  clear(state) {
    state.data = {};
    state.kode_transaksi = "";
    state.checkout_at = null;
    state.expired_at = null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
