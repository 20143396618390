import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  },
  {
    path: '/:slug/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue')
  },
  {
    path: '/:slug/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue')
  },
  {
    path: '/:slug/history/:kode',
    name: 'HistoryDetail',
    component: () => import(/* webpackChunkName: "history-detail" */ '../views/HistoryDetail.vue')
  },
  {
    path: '/:slug',
    name: 'pesan',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pesan.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
